import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import axios from "axios";

import { useStaticQuery, graphql } from "gatsby";
import moment from "moment";
import ReviewCardCt from "./review-card-ct";
import { GatsbyImage } from "gatsby-plugin-image";

const ReviewSection = () => {
	const [reviewsTotal, setReviewsTotal] = useState([]);

	var reviewsCt = [];
	var totalReviews = [];

	var num = 0;

	useEffect(() => {
		axios
			.get(
				`https://trade-profile.checkatrade.com/companies/denisbonnicielectricians/reviews?page=1&itemsPerPage=3&sortBy=createDate.desc`
			)
			.then((res) => {
				const reviewData = res.data.items;
				reviewsCt = reviewData;
				handleReviewsSecond();
			});
	}, [num]);

	const handleReviewsSecond = () => {
		totalReviews = totalReviews.concat(
			reviewsCt.map((review) => ({
				reviewBody: review.body,
				datePublished: new Date(moment(review.date).format("YYYY-MM-DD")),
				author: null,
				source: null,
			}))
		);
		setReviewsTotal(
			totalReviews.sort((a, b) => b.datePublished - a.datePublished)
		);
	};

	const data = useStaticQuery(graphql`
		query {
			CTImg: wpMediaItem(title: { eq: "Checkatrade-Logo" }) {
				altText
				localFile {
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: TRACED_SVG
						)
					}
				}
			}
			TSImg: wpMediaItem(title: { eq: "Trading-Standards-Image" }) {
				altText
				localFile {
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: TRACED_SVG
						)
					}
				}
			}
		}
	`);

	const CTImg = data.CTImg?.localFile.childImageSharp.gatsbyImageData;
	const TSImg = data.TSImg?.localFile.childImageSharp.gatsbyImageData;

	return (
		<Container className="pt-5 pt-md-0">
			<Row>
				<Col>
					<h1 className="text-center mb-1">Reviews and testimonials</h1>
					<hr
						className="bg-primary mx-auto mt-0"
						style={{ height: "2px", width: "6rem" }}
					/>
				</Col>
			</Row>
			<Row className="justify-content-center">
				{reviewsTotal.map((review) => (
					<Col lg={4} md={6} xs={12}>
						<ReviewCardCt review={review} />
					</Col>
				))}
			</Row>
			<Row className="justify-content-center pt-md-5">
				<Col className="text-center" md={6}>
					{" "}
					<a
						target="_blank"
						rel="noreferrer"
						href="https://www.checkatrade.com/trades/denisbonnicielectricians"
					>
						<GatsbyImage
							className="mx-auto mw-60"
							image={CTImg}
							alt={data.CTImg?.altText}
						/>
					</a>
				</Col>
				<Col className="text-center mt-4 mt-md-0" md={6}>
					<a
						target="_blank"
						rel="noreferrer"
						href="https://www.buywithconfidence.gov.uk/profile/denis-bonnici-electricians-ltd/7466/"
					>
						<GatsbyImage
							className="mx-auto mw-50"
							image={TSImg}
							alt={data.TSImg?.altText}
						/>
					</a>
				</Col>
			</Row>
		</Container>
	);
};

export default ReviewSection;
